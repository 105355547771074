<template>
  <v-card>
    <v-card-title>
      <v-layout row wrap justify-space-between align-center>
        <span>Preview</span>
        <v-icon color="black" @click="$emit('close')">mdi-close</v-icon>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="rows"
        :items-per-page-options="[10, 25, 50, 100, { text:'All' , value: -1 }]"
      >
<!--        <template slot="items" slot-scope="props">-->
<!--          <td v-for="(item, index) in props.item" :key="index">{{ item }}</td>-->
<!--        </template>-->
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: ['table'],
    data: () => ({
      headers: [],
      rows: [],
    }),
    created() {
      this.headers = this.table.slice(0, 1)[0].map(header => ({
        text: header,
        value: header.toLowerCase().replace(' ', ''),
        align: 'left',
        sortable: false,
      }));
      this.rows = [...this.table.slice(1)];
      if (this.rows[this.rows.length - 1].length === 1) {
        this.rows.splice(this.rows.length - 1, 1);
      }
    },
  };
</script>

<style>

</style>
